import React from 'react';
import './index.scss';
import bookmark from 'public/images/bookmark.png';
import DateChip from '@/shared/ui/DateChip/DateChip';
import { useNavigate } from 'react-router-dom';
import { ServerApiEventsClientDtoEventsListResponseDTO } from '@/shared/api/gen/data-contracts';
import { getShortText } from '@/shared/utils/HOC/getShortText';

type Props = {
	event: ServerApiEventsClientDtoEventsListResponseDTO;
};

const EventCard = ({ event }: Props) => {
	const navigate = useNavigate();
	const date = new Date(event.start_at);
	const dateOptions: Intl.DateTimeFormatOptions = {
		day: 'numeric',
		month: 'long',
		hour: '2-digit',
		minute: '2-digit',
	};
	return (
		<div
			className='EventCard'
			onClick={() => navigate(`/events/${event?.id}`)}>
			{event?.preview && (
				<div className='EventCard_imageWrapper'>
					<img
						src={event?.preview}
						className='EventCard_imageWrapper_preview'
						alt='preview'
					/>
					<img
						src={bookmark}
						className='EventCard_imageWrapper_bookmark'
						alt='bookmark'
					/>
				</div>
			)}
			<DateChip className='mt-8'>
				<span className='text-16 medium'>{date.toLocaleDateString('ru-RU', dateOptions)}</span>
			</DateChip>
			<h1 className='text-16 medium mt-8'>{event.title}</h1>
			<h2 className='text-14 regular mt-3'>
				{event?.description && getShortText(event?.description, { maxLength: 100 }).text + '...'}
			</h2>
		</div>
	);
};

export default EventCard;
