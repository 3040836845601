import React, { SetStateAction } from 'react';
import './index.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import copy from 'public/images/Copy.png';
import qr from 'public/images/QR.png';

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	setVisible: React.Dispatch<SetStateAction<boolean>>;
	link: string;
}

const InviteReferral = ({ setVisible, link, ...props }: Props) => {
	return (
		<div className={`InviteReferral ${props?.className}`}>
			<CopyToClipboard
				text={link}
				onCopy={() => alert('текст был скопирован')}>
				<div className='InviteReferral_linkWrapper'>
					<div className='text-16 regular'>https://realweb.ru/referal</div>
					<img
						src={copy}
						alt='copy'
						style={{ width: '24px', height: '24px' }}
					/>
				</div>
			</CopyToClipboard>
			<img
				onClick={() => setVisible(true)}
				src={qr}
				alt='qr'
				style={{ width: '44px', height: '44px' }}
			/>
		</div>
	);
};

export default InviteReferral;
