import React, {SetStateAction} from 'react';
import './index.scss';
import BackgroundAnimation from "@/shared/ui/animations/BackgroundAnimation/BackgroundAnimation";
import { motion } from 'framer-motion';
import close from "public/images/close.png";
import QRCode from "react-qr-code";
import CustomButton, {ButtonVariant} from "@/shared/ui/buttons/Button";
import noodle from 'public/images/Noodle4.png';

type Props = {
    setVisible: React.Dispatch<SetStateAction<boolean>>;
    link: string
}

const InviteModal = ({setVisible, link}: Props) => {
    return (
        <>
            <BackgroundAnimation setVisible={setVisible}/>
            <motion.div
                style={{
                    display: 'flex',
                    position: 'fixed',
                    left: '0px',
                    bottom: '0px',
                    width: '100%',
                    zIndex: 15,
                }}
                initial={{y: '100svh'}}
                animate={{y: '0'}}
                exit={{y: '100svh'}}
                transition={{ease: 'easeInOut', duration: 0.5}}
            >
                <div className='InviteModal'>
                    <img
                        className='InviteModal_noodle'
                        src={noodle}
                        alt=''
                    />
                    <img className='InviteModal_close'
                         src={close}
                         alt='close'
                         onClick={() => setVisible(false)}
                    />
                    <h1 className='text-22 medium'>Поделись QR с другом</h1>
                    <div className='InviteModal_content'>
                        <h2 className='text-16 medium mt-16'>Краткое описание партнерской программы и как она работает с
                            приглашениями</h2>
                        <div className='InviteModal_content_QR mt-24'>
                            <QRCode
                                size={200}
                                value={link}
                                style={{
                                    position: "absolute",
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)'
                                }}
                            />
                        </div>
                    </div>
                    <CustomButton
                        theme={ButtonVariant.default}
                        onClick={() => setVisible(false)}
                        className='mt-24'
                    >
                        <span className='text-18 medium'>Готово!</span>
                    </CustomButton>
                </div>
            </motion.div>
        </>
    );
};

export default InviteModal;