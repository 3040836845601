import React from "react";
import ThemeContextProvider from "./ThemeContext";
import EventsContextProvider from "@/app/context/EventsContext";

/**
 * Точка включения всех необходимых глобальных контекстов
 * @param param0 
 * @returns 
 */
export function ProviderStore({ children }: any) {
    return (
        <ThemeContextProvider>
            <EventsContextProvider>
                {children}
            </EventsContextProvider>
        </ThemeContextProvider>
    )
}