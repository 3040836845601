import React, {SetStateAction, useState} from 'react';
import './index.scss';
import {CheckboxInputChip} from "@/shared/ui/inputs/ChecboxInputs/Checkbox";
import {ServerApiTagsClientDtoTagsListResponseDTO, TagGroupsListResponseDTO} from "@/shared/api/gen/data-contracts";
import { motion } from 'framer-motion';
import close from 'public/images/close.png';
import CustomButton from "@/shared/ui/buttons/Button";
import BackgroundAnimation from "@/shared/ui/animations/BackgroundAnimation/BackgroundAnimation";

type Props = {
    tagGroup: TagGroupsListResponseDTO,
    tags: ServerApiTagsClientDtoTagsListResponseDTO[],
    filters: number[],
    setIsVisible: React.Dispatch<SetStateAction<boolean>>,
    setFilters: React.Dispatch<SetStateAction<number[]>>
}

const ModalFilterInterests = ({tagGroup, tags, filters, setIsVisible, setFilters}: Props) => {
    const [tempFilters, setTempFilters] = useState<number[]>([...filters]);
    const handleSave = () => {
        setIsVisible(false);
        setFilters([...tempFilters]);
    }
    const handleClick = (id: number) => {
        setTempFilters((prev) => {
            if (prev.includes(id)) {
                return [...prev.filter((item) => item !== id)];
            } else {
                return [...prev, id];
            }
        })
    }
    return (
        <>
            <BackgroundAnimation setVisible={setIsVisible}/>
            <motion.div
                style={{
                    display: 'flex',
                    position: 'fixed',
                    left: '0px',
                    bottom: '0px',
                    width: '100%',
                    minHeight: '30svh',
                    zIndex: 15
                }}
                initial={{y: '100svh'}}
                animate={{y: '0svh'}}
                exit={{y: '100svh'}}
                transition={{ease: 'easeInOut', duration: 0.5}}
            >
                <div className='ModalFilterInterests'>
                    <h1 className='text-22 medium'>{tagGroup.title}</h1>
                    <img src={close} alt='close' onClick={() => setIsVisible(false)}/>
                    {tags?.map(tag => {
                        const active = !!tempFilters?.find(id => id == tag.id);
                        return (tag?.group_ref == tagGroup?.id) &&
                            <CheckboxInputChip
                                title={tag.title}
                                active={active}
                                onChange={() => handleClick(tag.id)}
                                variant='transparent'
                            />
                    })}
                    <CustomButton
                        style={{marginTop: "auto"}}
                        onClick={handleSave}
                    >
                        <span className='text-18 medium'>Применить</span>
                    </CustomButton>
                </div>
            </motion.div>
        </>
    );
};

export default ModalFilterInterests;