import React, {SetStateAction} from 'react';
import {motion} from "framer-motion";

type Props = {
    setVisible: React.Dispatch<SetStateAction<boolean>>
}

const BackgroundAnimation = ({setVisible}: Props) => {
    return (
        <motion.div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'black',
                zIndex: 15
            }}
            initial={{opacity: 0}}
            animate={{opacity: 0.35}}
            exit={{opacity: 0}}
            transition={{ease: 'easeInOut', duration: 0.5}}
            onClick={() => {setVisible(false);console.log(123)}}
        />
    );
};

export default BackgroundAnimation;