import React, { useState } from 'react';
import './index.scss';
import EventRegistrationImage from '@/widgets/EventRegistrationImage/EventRegistrationImage';
import mockBlueprint from 'public/images/mock_blueprint.png';
import CustomButton, { ButtonVariant } from '@/shared/ui/buttons/Button';
import close from 'public/images/close.png';
import arrow_right from 'public/images/ArrowRight.png';
import EventRegistrationCard from '@/widgets/EventRegistrationCard/EventRegistrationCard';
import DateChip from '@/shared/ui/DateChip/DateChip';
import Background from '@/shared/ui/background/Background';
import PageLayout from '@/shared/ui/layouts/PageLayout';
import communication from 'public/images/communication.png';
import { useParams } from 'react-router-dom';
import useFetch from '@/shared/hooks/useFetch';
import {
	ServerApiEventsRegistrationClientDtoEventRegistrationResponseDTO,
	ServerApiEventsClientDtoEventsResponseDTO,
	ServerApiTagsClientDtoTagsListResponseDTO,
} from '@/shared/api/gen/data-contracts';
import api from '@/shared/api/api';
import calendarPlus from 'public/images/Calendar_plus.png';
import copy from 'public/images/Copy.png';
import qr from 'public/images/QR.png';
import qrWhite from 'public/images/QRWhite.png';
import EventTags from '@/shared/ui/EventTags/EventTags';
import RichText from '@/shared/ui/RichText/RichText';
import { getShortText } from '@/shared/utils/HOC/getShortText';
import { AnimatePresence } from 'framer-motion';
import TicketModal from '@/widgets/TicketModal/TicketModal';
import InviteModal from '@/widgets/InviteModal/InviteModal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import InviteReferral from '@/shared/ui/InviteReferral/InviteReferal';
import ArrowButton from '@/shared/ui/ArrowButton/ArrowButton';

const EventPage = () => {
	const { id } = useParams<'id'>();
	const eventId = id as unknown as number;
	const [isTicketOpen, setIsTicketOpen] = useState<boolean>(false);
	const [isInviteOpen, setIsInviteOpen] = useState<boolean>(false);
	const { data: event, error: EventError } = useFetch<ServerApiEventsClientDtoEventsResponseDTO>(
		() => api.events.getEventsDetailApiEventsIdGet(id as unknown as number),
		// TODO: В useFetch так нельзя делать!
		// .catch((res) => alert(`Произошла ошибка со статус кодом ${res.status}`)),
		undefined,
		[]
	);
	const { data: tags } = useFetch<ServerApiTagsClientDtoTagsListResponseDTO[]>(
		() => api.tags.getTagsApiTagsGet(),
		[],
		[]
	);
	const {
		data: eventRegistration,
		refresh,
		error: eventRegistrationError,
		loading: eventLoading,
	} = useFetch<ServerApiEventsRegistrationClientDtoEventRegistrationResponseDTO>(
		() => api.events.getEventRegistrationDetailApiEventsRegistrationEventIdGet(eventId)
		// TODO: В useFetch так нельзя делать!
		// .catch((res) => alert(`Произошла ошибка со статус кодом ${res.status}`))
	);
	const register = () => {
		api.events
			.registerOnEventApiEventsRegistrationEventEventIdPut(eventId)
			.catch((res) => alert(`Произошла ошибка со статус кодом ${res.status}`))
			.then(() => refresh());
	};
	const date = new Date(event?.start_at);
	const dateOptions: Intl.DateTimeFormatOptions = {
		day: 'numeric',
		month: 'long',
		hour: '2-digit',
		minute: '2-digit',
	};
	const isUserRegistered =
		eventRegistration?.status == 'check_in_passed' || eventRegistration?.status == 'pending_check_in';
	const eventTags = tags.filter((tag) => event?.tags_ref_list?.includes(tag.id)).map((tag) => tag.title);
	if (eventLoading) return null;
	return (
		<PageLayout>
			<div className='EventRegistration invisibleScroll'>
				<EventRegistrationImage
					image={event?.preview}
					isRegistered={isUserRegistered}
				/>
				{isUserRegistered && (
					<CustomButton
						theme={ButtonVariant.orange}
						className='br-12 g-12'>
						<img
							src={calendarPlus}
							alt='calendar'
							style={{ width: '24px', height: '24px' }}
						/>
						<span className='text-18 medium'>Добавить в календарь</span>
					</CustomButton>
				)}
				<EventRegistrationCard>
					<DateChip>
						<span className='text-16 medium'>{date.toLocaleDateString('ru-RU', dateOptions)}</span>
					</DateChip>
					<h1 className='text-22 medium mt-12 mb-12'>{event?.title}</h1>
					<h2 className='text-16 regular mt-8'>
						{event?.description && getShortText(event?.description, { maxLength: 100 }).text}
					</h2>
				</EventRegistrationCard>
				<EventRegistrationCard>
					{/* <h1 className='text-22 medium'>{event?.location}</h1> */}
					<RichText text={event?.location} />
					{/* <h2 className='text-16 regular mt-4 EventRegistrationCard_place'>{event?.location}</h2> */}
					<img
						onClick={() => window.open(mockBlueprint)}
						className='mt-12'
						src={mockBlueprint}
						alt='place_image'
					/>
				</EventRegistrationCard>
				<EventRegistrationCard>
					<h1 className='text-22 medium mb-12'>Описание события</h1>
					<RichText text={event?.description} />
				</EventRegistrationCard>
				{isUserRegistered && (
					<EventRegistrationCard>
						<h1 className='text-22 medium'>Пригласить друга</h1>
						<InviteReferral
							setVisible={setIsInviteOpen}
							link='https://realweb.ru/contacts'
							className='mt-12'
						/>
					</EventRegistrationCard>
				)}
				{!!eventTags && eventTags?.length > 0 && (
					<EventRegistrationCard>
						<EventTags tags={eventTags} />
					</EventRegistrationCard>
				)}
				{/*не придумал как назвать кнопку и пока не совсем понимаю, что она делает, поэтому пока не стал выносить*/}
				<ArrowButton
					text='Написать в поддержку'
					icon={communication}
				/>
				<div className='EventRegistration_register'>
					{isUserRegistered && eventRegistration?.ticket_id ? (
						<CustomButton
							onClick={() => setIsTicketOpen(true)}
							className='g-12'>
							<img
								src={qrWhite}
								alt='qr'
								style={{ width: '24px', height: '24px' }}
							/>
							<span className='text-18 medium'>Открыть билет</span>
						</CustomButton>
					) : (
						<CustomButton onClick={register}>
							<span className='text-18 medium'>Зарегистрироваться</span>
						</CustomButton>
					)}
				</div>
				<AnimatePresence>
					{isTicketOpen && eventRegistration?.ticket_id && (
						<TicketModal
							event={event}
							setVisible={setIsTicketOpen}
							link={`https://admin.rw.proga.space/check_in/${eventRegistration?.ticket_id}`}
						/>
					)}
					{isInviteOpen && (
						<InviteModal
							setVisible={setIsInviteOpen}
							link='invite link here'
						/>
					)}
				</AnimatePresence>
				<Background background={'#F5F8FB'} />
			</div>
		</PageLayout>
	);
};

export default EventPage;
