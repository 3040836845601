import useTG from '@/shared/hooks/useTG';
import Navbar from '@/widgets/Navbar/Navbar';
import React, { ReactElement, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
	navbar?: boolean;
	children: React.ReactElement;
}
/**
 * Переиспользуемый компонент лейаута страницы
 *
 * @param navbar - показывает Navbar или скрывает его
 * @param children - содержимое страницы
 * @returns children
 */
function PageLayout({ navbar, children }: Props) {
	const { WebApp } = useTG({ requestWriteAccess: true });
	const navigate = useNavigate();

	const buttonClick = () => {
		navigate(-1);
	};

	React.useEffect(() => {
		if (WebApp?.onEvent) {
			WebApp?.BackButton?.show();
			WebApp?.onEvent('backButtonClicked', buttonClick);
			return () => {
				WebApp?.offEvent('backButtonClicked', buttonClick);
				WebApp?.BackButton?.hide();
			};
		}
	}, [WebApp]);

	return (
		<>
			{children}
			{navbar && <Navbar />}
		</>
	);
}

export default PageLayout;
