import React from 'react';
import { FlexRow } from '../layouts/Flex/Flex';
import './index.scss';

// TODO: добавить других вариаций
type StyleVariant = 'white' | 'transparent';
interface Props extends React.HTMLAttributes<HTMLDivElement> {
	variant: StyleVariant;
}

function ChipField(props: Props) {
	const { variant, ...restProps } = props;
	const variantStyleClasses: Record<StyleVariant, string> = {
		white: 'white p-12 g-12 br-12',
		transparent: 'transparent py-10 g-8'
	};
	const variantStyles: Record<StyleVariant, object> = {
		white: { backgroundColor: 'rgba(255, 255, 255, 1)' },
		transparent: {backgroundColor: 'transparent'}
	};
	return (
		<FlexRow
			{...restProps}
			style={{ width: 'fit-content', ...variantStyles[variant], ...(restProps.style || {}) }}
			className={`ChipField ${variantStyleClasses[variant]} ${restProps?.className}`}>
			{restProps.children}
		</FlexRow>
	);
}

export default ChipField;
