import React, {useState} from "react";

export enum EventsCategory {
    FUTURE,
    PAST,
    INVITES
}
export const EventsContext = React.createContext<EventsCategory>({} as EventsCategory);

export default function EventsContextProvider({children}: any){
    const [viewedEventsCategory, setViewedEventsCategory] = useState<EventsCategory>(EventsCategory.PAST);
    const providerInterface = React.useMemo(() => ({
        viewedEventsCategory, setViewedEventsCategory
    }), []);
    return(
        <EventsContext.Provider value={providerInterface}>
            {children}
        </EventsContext.Provider>
    )
}
