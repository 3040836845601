/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  HTTPValidationError,
  ServerApiUsersInvitesClientDtoUserRegistrationInviteResponseDTO,
  UserRegistrationDTO,
  UserResponseDTO,
  UserWishListResponseDTO,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Users<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Users, Users
   * @name GetUserProfileApiUsersProfileGet
   * @summary Get User Profile
   * @request GET:/api/users/profile
   * @response `200` `UserResponseDTO` Successful Response
   */
  getUserProfileApiUsersProfileGet = (params: RequestParams = {}) =>
    this.request<UserResponseDTO, any>({
      path: `/api/users/profile`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users, Users
   * @name RegistrationApiUsersRegistrationPut
   * @summary Registration
   * @request PUT:/api/users/registration
   * @response `204` `void` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  registrationApiUsersRegistrationPut = (data: UserRegistrationDTO, params: RequestParams = {}) =>
    this.request<void, HTTPValidationError>({
      path: `/api/users/registration`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Users, Users
   * @name SyncTgAvatarApiUsersSyncTgAvatarPut
   * @summary Sync Tg Avatar
   * @request PUT:/api/users/sync_tg_avatar
   * @response `204` `void` Successful Response
   */
  syncTgAvatarApiUsersSyncTgAvatarPut = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/users/sync_tg_avatar`,
      method: "PUT",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users, Users
   * @name AddToWishlistApiUsersWishlistEventRefPost
   * @summary Add To Wishlist
   * @request POST:/api/users/wishlist/{event_ref}
   * @response `204` `void` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  addToWishlistApiUsersWishlistEventRefPost = (eventRef: number, params: RequestParams = {}) =>
    this.request<void, HTTPValidationError>({
      path: `/api/users/wishlist/${eventRef}`,
      method: "POST",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users, Users
   * @name RemoveFromWishlistApiUsersWishlistEventRefDelete
   * @summary Remove From Wishlist
   * @request DELETE:/api/users/wishlist/{event_ref}
   * @response `204` `void` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  removeFromWishlistApiUsersWishlistEventRefDelete = (eventRef: number, params: RequestParams = {}) =>
    this.request<void, HTTPValidationError>({
      path: `/api/users/wishlist/${eventRef}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users, Users
   * @name GetWishlistApiUsersWishlistGet
   * @summary Get Wishlist
   * @request GET:/api/users/wishlist
   * @response `200` `(UserWishListResponseDTO)[]` Successful Response
   */
  getWishlistApiUsersWishlistGet = (params: RequestParams = {}) =>
    this.request<UserWishListResponseDTO[], any>({
      path: `/api/users/wishlist`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users, Users, UserRegistrationInvite
   * @name GetReferralLinkApiUsersInvitesReferralLinkGet
   * @summary Get Referral Link
   * @request GET:/api/users/invites/referral_link
   * @response `200` `string` Successful Response
   */
  getReferralLinkApiUsersInvitesReferralLinkGet = (params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/users/invites/referral_link`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users, Users, UserRegistrationInvite
   * @name GetUserRegistrationInviteDetailApiUsersInvitesIdGet
   * @summary Get User Registration Invite Detail
   * @request GET:/api/users/invites/{_id}
   * @response `200` `ServerApiUsersInvitesClientDtoUserRegistrationInviteResponseDTO` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getUserRegistrationInviteDetailApiUsersInvitesIdGet = (id: string, params: RequestParams = {}) =>
    this.request<ServerApiUsersInvitesClientDtoUserRegistrationInviteResponseDTO, HTTPValidationError>({
      path: `/api/users/invites/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
}
