import React from "react";
import './index.scss';

const DateChip = ({children, ...props}: React.HTMLAttributes<HTMLDivElement>) => {
    //тут будет какая-то логика по работе с датой
    return (
        <div className={`DateChip ${props?.className}`}>
            {children}
        </div>
    )
}

export default DateChip;