/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { HTTPValidationError } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Url<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name GetUploadsUploadsUrlGet
   * @summary Get Uploads
   * @request GET:/uploads/{url}
   * @response `200` `void` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getUploadsUploadsUrlGet = (url: string, params: RequestParams = {}) =>
    this.request<void, HTTPValidationError>({
      path: `/uploads/${url}`,
      method: "GET",
      ...params,
    });
}
