import { motion } from 'framer-motion';
import React, { SetStateAction } from 'react';
import './index.scss';
import { ServerApiEventsClientDtoEventsResponseDTO } from '@/shared/api/gen/data-contracts';
import qr from 'public/images/QR_Background.png';
import BackgroundAnimation from '@/shared/ui/animations/BackgroundAnimation/BackgroundAnimation';
import close from 'public/images/close.png';
import CustomButton, { ButtonVariant } from '@/shared/ui/buttons/Button';
import noodle from 'public/images/Noodle3.png';
import QRCode from 'react-qr-code';

type Props = {
	event: ServerApiEventsClientDtoEventsResponseDTO;
	setVisible: React.Dispatch<SetStateAction<boolean>>;
	link: string;
};

const TicketModal = ({ event, setVisible, link }: Props) => {
	const date = new Date(event?.start_at);
	const dateOptions: Intl.DateTimeFormatOptions = {
		day: 'numeric',
		month: 'long',
		/*hour: '2-digit',
        minute: '2-digit',*/
		year: 'numeric',
	};
	return (
		<>
			<BackgroundAnimation setVisible={setVisible} />
			<motion.div
				style={{
					display: 'flex',
					position: 'fixed',
					left: '0px',
					bottom: '0px',
					width: '100%',
					zIndex: 15,
				}}
				initial={{ y: '100svh' }}
				animate={{ y: '0' }}
				exit={{ y: '100svh' }}
				transition={{ ease: 'easeInOut', duration: 0.5 }}>
				<div className='TicketModal'>
					<img
						className='TicketModal_noodle'
						src={noodle}
						alt=''
					/>
					<img
						className='TicketModal_close'
						src={close}
						alt='close'
						onClick={() => setVisible(false)}
					/>
					<div className='TicketModal_content'>
						<h1 className='text-18 medium'>Покажи QR код на входе</h1>
						<div className='TicketModal_content_info mt-24'>
							<h1 className='text-18 medium'>{event?.title}</h1>
							<h2 className='text-12 regular mt-8'>{event?.location}</h2>
							<div className='text-16 medium'>
								<span>{date.toLocaleDateString('ru-RU', dateOptions)}</span>
								<span>{date.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })}</span>
							</div>
						</div>
						<div className='TicketModal_content_QR'>
							<img
								src={qr}
								alt='qr_background'
							/>
							<QRCode
								value={link}
								size={130}
								style={{
									position: 'absolute',
									top: '55%',
									left: '50%',
									transform: 'translate(-50%, -50%)',
								}}
							/>
						</div>
					</div>
					<CustomButton
						theme={ButtonVariant.orange}
						onClick={() => setVisible(false)}
						className='mt-24'>
						<span className='text-18 medium'>Готово!</span>
					</CustomButton>
				</div>
			</motion.div>
		</>
	);
};

export default TicketModal;
