import React from 'react';
import './index.scss';

function RichText({ text, theme = 'dark', ...props }: any) {
    return (
        <div
            dangerouslySetInnerHTML={{ __html: text }}
            {...props}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            className={`RichText ${theme} ${props?.className && props.className}`}
        />
    );
}

export default RichText;