/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { HTTPValidationError, ServerApiTagsClientDtoTagsListResponseDTO, TagsResponseDTO } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Tags<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Tags, Tags
   * @name GetTagsApiTagsGet
   * @summary Get Tags
   * @request GET:/api/tags
   * @response `200` `(ServerApiTagsClientDtoTagsListResponseDTO)[]` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getTagsApiTagsGet = (
    query?: {
      /** Group Ref */
      group_ref?: number | null;
      /** Limit */
      limit?: number | null;
      /** Offset */
      offset?: number | null;
    },
    params: RequestParams = {},
  ) =>
    this.request<ServerApiTagsClientDtoTagsListResponseDTO[], HTTPValidationError>({
      path: `/api/tags`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Tags, Tags
   * @name GetTagsDetailApiTagsIdGet
   * @summary Get Tags Detail
   * @request GET:/api/tags/{_id}
   * @response `200` `TagsResponseDTO` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getTagsDetailApiTagsIdGet = (id: number, params: RequestParams = {}) =>
    this.request<TagsResponseDTO, HTTPValidationError>({
      path: `/api/tags/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
}
