import React from 'react';
import './index.scss';

export function FlexRow({ children, ...props }: React.HTMLAttributes<HTMLDivElement>) {
	return (
		<div
			{...props}
			className={`Flex row ${props?.className}`}>
			{children}
		</div>
	);
}

export function FlexColumn({ children, ...props }: React.HTMLAttributes<HTMLDivElement>) {
	return (
		<div
			{...props}
			className={`Flex column ${props?.className}`}>
			{children}
		</div>
	);
}
