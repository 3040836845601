/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  EventRegistrationStatus,
  EventStatus,
  HTTPValidationError,
  ServerApiEventsClientDtoEventsListResponseDTO,
  ServerApiEventsClientDtoEventsResponseDTO,
  ServerApiEventsRegistrationClientDtoEventRegistrationResponseDTO,
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Events<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Events, Events
   * @name GetEventsApiEventsGet
   * @summary Get Events
   * @request GET:/api/events
   * @response `200` `(ServerApiEventsClientDtoEventsListResponseDTO)[]` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getEventsApiEventsGet = (
    query?: {
      /** Organization Ref */
      organization_ref?: number | null;
      /** Tag Refs */
      tag_refs?: number[] | null;
      /** In Wishlist */
      in_wishlist?: boolean | null;
      /** Upcoming */
      upcoming?: boolean | null;
      /** Event Status List */
      event_status_list?: EventStatus[] | null;
      /** Registration Status List */
      registration_status_list?: EventRegistrationStatus[] | null;
      /** Start In Range */
      start_in_range?: any[] | null;
      /** Limit */
      limit?: number | null;
      /** Offset */
      offset?: number | null;
    },
    params: RequestParams = {},
  ) =>
    this.request<ServerApiEventsClientDtoEventsListResponseDTO[], HTTPValidationError>({
      path: `/api/events`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Events, Events
   * @name GetEventsDetailApiEventsIdGet
   * @summary Get Events Detail
   * @request GET:/api/events/{_id}
   * @response `200` `ServerApiEventsClientDtoEventsResponseDTO` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getEventsDetailApiEventsIdGet = (id: number, params: RequestParams = {}) =>
    this.request<ServerApiEventsClientDtoEventsResponseDTO, HTTPValidationError>({
      path: `/api/events/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Events, Events
   * @name GetIcsFileApiEventsIcsHashIcsGet
   * @summary Get Ics File
   * @request GET:/api/events/{ics_hash}/ics
   * @response `200` `void` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getIcsFileApiEventsIcsHashIcsGet = (icsHash: string, params: RequestParams = {}) =>
    this.request<void, HTTPValidationError>({
      path: `/api/events/${icsHash}/ics`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Events, Events
   * @name GetIcsHashApiEventsEventIdIcsHashGet
   * @summary Get Ics Hash
   * @request GET:/api/events/{event_id}/ics_hash
   * @response `200` `string` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getIcsHashApiEventsEventIdIcsHashGet = (eventId: number, params: RequestParams = {}) =>
    this.request<string, HTTPValidationError>({
      path: `/api/events/${eventId}/ics_hash`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Events, EventRegistrations
   * @name RegisterOnEventApiEventsRegistrationEventEventIdPut
   * @summary Register On Event
   * @request PUT:/api/events/registration/event/{event_id}
   * @response `204` `void` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  registerOnEventApiEventsRegistrationEventEventIdPut = (eventId: number, params: RequestParams = {}) =>
    this.request<void, HTTPValidationError>({
      path: `/api/events/registration/event/${eventId}`,
      method: "PUT",
      ...params,
    });
  /**
   * No description
   *
   * @tags Events, EventRegistrations
   * @name RegisterWithTicketIdApiEventsRegistrationTicketIdPut
   * @summary Register With Ticket Id
   * @request PUT:/api/events/registration/{ticket_id}
   * @response `204` `void` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  registerWithTicketIdApiEventsRegistrationTicketIdPut = (ticketId: string, params: RequestParams = {}) =>
    this.request<void, HTTPValidationError>({
      path: `/api/events/registration/${ticketId}`,
      method: "PUT",
      ...params,
    });
  /**
   * No description
   *
   * @tags Events, EventRegistrations
   * @name GetEventRegistrationDetailApiEventsRegistrationEventIdGet
   * @summary Get Event Registration Detail
   * @request GET:/api/events/registration/{event_id}
   * @response `200` `ServerApiEventsRegistrationClientDtoEventRegistrationResponseDTO` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getEventRegistrationDetailApiEventsRegistrationEventIdGet = (eventId: number, params: RequestParams = {}) =>
    this.request<ServerApiEventsRegistrationClientDtoEventRegistrationResponseDTO, HTTPValidationError>({
      path: `/api/events/registration/${eventId}`,
      method: "GET",
      format: "json",
      ...params,
    });
}
