import React, {SetStateAction, useState} from "react";
import './index.scss';
import DropdownFilter from "@/shared/ui/DropdownFilter/DropdownFilter";
import calendar from 'public/images/calendar.png';
import {ServerApiTagsClientDtoTagsListResponseDTO, TagGroupsListResponseDTO} from "@/shared/api/gen/data-contracts";

type Props = {
    tagGroups: TagGroupsListResponseDTO[],
    setCurrentTagGroup: React.Dispatch<SetStateAction<TagGroupsListResponseDTO>>,
    setIsVisible: React.Dispatch<SetStateAction<boolean>>,
    setIsCalendarVisible: React.Dispatch<SetStateAction<boolean>>
}

const EventFilters = ({tagGroups, setCurrentTagGroup, setIsVisible, setIsCalendarVisible}: Props) => {
    return(
        <div className='EventFilters invisibleScroll'>
            <img
                src={calendar}
                alt='calendar'
                onClick={() => setIsCalendarVisible(true)}
            />
            {tagGroups.map(tagGroup => {
                return <DropdownFilter
                    title={tagGroup.title}
                    onCLick={() => {
                        setCurrentTagGroup(tagGroup);
                        setIsVisible(true);
                    }}
                />
            })}
        </div>
    )
}

export default EventFilters;