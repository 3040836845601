import React from 'react';
import './index.scss';

const Background = ({background}: {background?: string}) => {
	return (
		<div className='Background_wrapper'>
			<div className='Background' style={{background: background}}>
				<div className='Background_circle1' />
				<div className='Background_circle2' />
				<div className='Background_circle3' />
				<div className='Background_circle4' />
				<div className='Background_circle5' />
				<div className='Background_circle6' />
			</div>
		</div>
	);
};

export default Background;
