import React from 'react';
import './index.scss';
import bookmark from 'public/images/bookmark.png';
import noodle from 'public/images/noodle2.png';

type Props = {
	image?: string | null;
	isRegistered: boolean;
};

const EventRegistrationImage = ({ image, isRegistered }: Props) => {
	return (
		<div className='EventRegistrationImage'>
			{image && (
				<img
					className='EventRegistrationImage_image'
					src={`/${image}`}
					alt='image'
				/>
			)}
			<img
				className='EventRegistrationImage_bookmark'
				src={bookmark}
				alt='bookmark'
			/>
			{!isRegistered && (
				<img
					className='EventRegistrationImage_noodle'
					src={noodle}
					alt='noodle'
				/>
			)}
		</div>
	);
};

export default EventRegistrationImage;
